import styles from "./styles.module.scss";
import { Options } from '@splidejs/splide';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import {useResponsive} from "../../core-nextv3/util/useResponsive";
import { CustomCountdownItem } from "./CustomCountdownItem";

export const CountdownSlider = ({ countdown }: any) => 
{
    //console.error('countdown', countdown);

    const { isDesktop, isClient } = useResponsive();

    if(!isClient)
    {
        return <></>
    }

    const mainOptions : Options = {
        type       : 'loop',
        rewind     : true,
        perPage    : 1,
        perMove    : 1,
        width      : '100vw',
        pagination : true,
        slideFocus : true,
        start      : 0,
        arrows     : false,
        autoplay   : true,
        interval   : 3000,
    };

    return (
      <div className={styles.countdownSlider}>
        <div className={styles.content}>
            <Splide
                options={mainOptions}
                aria-labelledby=""
              >
                {countdown?.map((item:any, index:any) => (
                    <SplideSlide key={index}>
                        <CustomCountdownItem item={item} dateFormte="YYYY-MM-DDTHH:mm:ss" />                        
                    </SplideSlide>
                ))}
            </Splide>
        </div>
      </div>
    );
};
