import { TDate } from "../../../core-nextv3/model/TDate";
import styles from "./styles.module.scss";
import Countdown from "react-countdown";

interface CustomCountdownBannerProps {
  item: any;
  timerColor?: string;
  subtitleColor?: string;
  itemBackgroundColor?: string;
  contentBackgroundColor?: string;
  dateFormte?:string;
}

export const CustomCountdownItem = ({
  item,
  itemBackgroundColor = "white",
  timerColor = "#212121",
  subtitleColor = "#212121",
  contentBackgroundColor = "white",
  dateFormte = 'DD/MM/YYYY HH:mm:ss'
}: CustomCountdownBannerProps) => 
{
    const date = new TDate(item.date, dateFormte).toDate()

  const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
      return null;
    } else {
      return (        
        <div className={styles.customCountdown}>
          <div className={styles.card}>
            <div className={styles.title}>
                <h3>{item.title}</h3>
                <h2>{item.subtitle}</h2>
            </div>            
          </div>                        
          <div
            className={styles.content}
            style={{
              background: `${contentBackgroundColor}`,
            }}
          >
            <span
              style={{
                background: `${itemBackgroundColor}`,
                color: `${timerColor}`,
              }}
            >
              {days}
              <small
                style={{
                  color: `${subtitleColor}`,
                }}
              >
                Dias
              </small>
            </span>
            <div className={styles.separator}>:</div>
            <span
              style={{
                background: `${itemBackgroundColor}`,
                color: `${timerColor}`,
              }}
            >
              {hours < 10 ? "0" + hours : hours}{" "}
              <small
                style={{
                  color: `${subtitleColor}`,
                }}
              >
                Horas
              </small>
            </span>
            <div className={styles.separator}>:</div>
            <span
              style={{
                background: `${itemBackgroundColor}`,
                color: `${timerColor}`,
              }}
            >
              {minutes < 10 ? "0" + minutes : minutes}{" "}
              <small
                style={{
                  color: `${subtitleColor}`,
                }}
              >
                Minutos
              </small>
            </span>
            <div className={styles.separator}>:</div>
            <span
              style={{
                background: `${itemBackgroundColor}`,
                color: `${timerColor}`,
              }}
            >
              {seconds < 10 ? "0" + seconds : seconds}{" "}
              <small
                style={{
                  color: `${subtitleColor}`,
                }}
              >
                Segundos
              </small>
            </span>
          </div>
        </div>
      );
    }
  };

  return <Countdown date={date} renderer={renderer} />;
};
