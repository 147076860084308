import { useRef, useState } from "react";
import styles from "./styles.module.scss"

export const PhotosIntagram = ({ instagramFeeds, title, follow }: any) => 
{
    const [isHovering, setIsHovering] = useState(false);

    const playerRef : any = useRef();

    return(
        <div className={styles.photosInstagram}>
            <div className={styles.content}>
                <div className={styles.top}>
                    <p className={styles.title}>{title}</p>
                    <p className={styles.subTitle} onClick={() => window.open("https://www.instagram.com/espacomuna/")}>{follow}</p>
                </div>
                <div className={styles.images}>
                    {instagramFeeds?.map((item:any, index:any) =>(
                        <div className={styles.image} key={index}
                             onMouseEnter={() => setIsHovering(true)}
						     onMouseLeave={() => setIsHovering(false)}>
                            <img
                                alt=""
                                src={item?.thumb?._url}
                                onClick={() => window.open(item?.link)}
                            />                                         
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}