import Link from "next/link";
import { useRouter } from "next/router";
import styles from "./styles.module.scss";
// import { SectionTitle } from "../SectionTitle";
import { THEME_SETTING } from "../../setting/setting";
import { ImageSet } from "../../component-nextv3/ImageSet";

export const Matches = ({ homePage }: any) => {
  // console.log('matches', homePage.bannersMatchhes)
  const router = useRouter();

  return (
    <section className={styles.matches}>
      <div className={styles.content}>
        {/* <SectionTitle category="" sectionTitle="Matches" /> */}
        <h1 className={styles.titleMatches}>Categorias</h1>
        <div className={styles.matchesList}>
          {homePage?.bannersMatchhes?.map((matche: any, index: any) => (
            <figure key={index} onClick={() => router.push(matche?.url, undefined, { scroll: true })}>
              <div className={styles.imageContent}> 
                  <ImageSet
                    image={matche?.mobile}
                    width={1500}     
                    height={2160}
                    sizes="25vw"
                  />
              </div>
              {/* <Image
                src={matche?.mobile?._url}
                priority
                width={800}
                height={1800}
                alt="photo"
                onClick={() => router.push(`/categoria/${matche?.slug}`)}
              /> */}
              <div className={styles.label}>
                <p>{matche?.name}</p>
                {/* <Link href={`${matche?.url}`}>View all</Link> */}
              </div>
            </figure>
          ))}

          {/* <figure>
            <Image
              src="https://au.lattelierstore.com/wp-content/uploads/2021/09/pc-CityForest.jpg"
              priority
              width={800}
              height={1800}
              alt="photo"
            />
             <div>
              <p>City Forest</p>
              <Link href="/produtos">View all</Link>
            </div>
          </figure>

          <figure>
            <Image
              src="https://au.lattelierstore.com/wp-content/uploads/2021/09/pc-SpringPicnic.jpg"
              priority
              width={800}
              height={1800}
              alt="photo"
            />
             <div>
              <p>Spring Picnic</p>
              <Link href="/produtos">View all</Link>
            </div>
          </figure>
          <figure>
            <Image
              src="https://au.lattelierstore.com/wp-content/uploads/2021/09/pc-GentleDays.jpg"
              priority
              width={800}
              height={1800}
              alt="photo"
            />
            <div>
              <p>Gentle Days</p>
              <Link href="/produtos">View all</Link>
            </div>
          </figure>

          <figure>
            <Image
              src="https://au.lattelierstore.com/wp-content/uploads/2021/09/pc-CityForest.jpg"
              priority
              width={800}
              height={1800}
              alt="photo"
            />
             <div>
              <p>City Forest</p>
              <Link href="/produtos">View all</Link>
            </div>
          </figure>

          <figure>
            <Image
              src="https://au.lattelierstore.com/wp-content/uploads/2021/09/pc-SpringPicnic.jpg"
              priority
              width={800}
              height={1800}
              alt="photo"
            />
             <div>
              <p>Spring Picnic</p>
              <Link href="/produtos">View all</Link>
            </div>
          </figure> */}
        </div>
      </div>
    </section>
  );
};
