import styles from "./styles.module.scss";
import { ProductItem } from "../ProductItem";
import { Options } from "@splidejs/splide";
import { Splide } from "@splidejs/react-splide";
import { removeSlidesEmpty } from "../../core-nextv3/util/util";
import { useEffect, useState } from "react";

export const NewestProductsSlider = ({ title, products, colorTable, allLink }: any) => 
{
    const mainOptions : Options = {
        role         : "button",
        perPage      : 4,
        perMove      : 1,
        rewind       : true,
        rewindByDrag : true,
        gap          : "2rem",
        pagination   : false, 
        slideFocus   : true,
        arrows       : true,
        drag     	   : true,
        start        : 1,
        breakpoints  : {
            1024 : {
			  	perPage : 2.2, 
            }
        }
    };

    return (
        <div className={styles.newestSlider}>
            <div className={styles.content}>
                <div className={styles.top}>
                    <p className={styles.title}>{title}</p>
                    <div className={styles.sliderArrows}>
                        {allLink && <a href={allLink}>Ver Todos</a>}
                    </div>
                </div>
                <Splide
                    options={mainOptions}
                    aria-labelledby="Produtos"
                    onReady={removeSlidesEmpty}
                >
                    {products.map((product: any, index:number) => (
                        <div key={index} className={styles.slideItem + " splide__slide"}>
                            {product?.published && <ProductItem product={product} colorTable={colorTable}/>}
                        </div>
                    ))}                    
                </Splide>    
            </div>
        </div>
    );
};
