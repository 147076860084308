import styles from "./styls.module.scss";
import { GetStaticProps } from "next";
import { BannerSlider } from "../BannerSlider";
import withHeader from "../../utils/withHeader";
import { PhotosIntagram } from "../PhotosInstagram";
import { calls } from "../../core-nextv3/util/call.api";
import { NewestProductsSlider } from "../NewestProductsSlider";
import {useResponsive} from "../../core-nextv3/util/useResponsive";
import { collectionDocument, getDocument } from "../../core-nextv3/document/document.api";
import { collectionProductVariant, getProduct } from "../../core-nextv3/product/product.api";
import { COLOR_TABLE_SETTING, HOME_TWO_PAGE_SETTING, INSTAGRAM_FEEDS_SETTING, PARTIAL_PRODUCT_VARIANT_SETTING, THEME_SETTING } from "../../setting/setting";
import { innerHTML } from "../../core-nextv3/util/util";
import { CountdownSlider } from "../CountdownSlider";
import { Matches } from "@/Components/Matches";

const HomePageTwo = ({ homePage, colorTable, instagramFeeds, saleProducts }: any) => 
{
    // console.log('aaaaaaa', homePage)
	const { isDesktop } = useResponsive();

    return(
        <div className={styles.homePageTwo}>
			<BannerSlider images={homePage?.bannersHome} />

			{homePage?.countdown?.length > 0 &&
				<CountdownSlider countdown={homePage?.countdown} />
			}
			
			{homePage?.bannersMatchhes && <Matches homePage={homePage} /> }
			
			{homePage?.collectionPhotos?.length > 0 &&
				<div className={styles.collectionsImages}>
					{homePage?.collectionPhotos?.map((photo: any) => (
						<div key={photo?.id} className={styles.card}>
							{isDesktop &&
								<>
									<a href={photo?.link}> 
										<img src={photo?.imageDesk?._url} alt="" />
									</a>
									<div className={styles.cardName}>
										<p className={styles.name}>{photo?.name}</p>
									</div>
								</>
							}
							{!isDesktop &&
								<>
									<a href={photo?.link}>
										<img src={photo?.imageMobile?._url} alt="" />
									</a>
									<div className={styles.cardNameMobile}>
										<p className={styles.name}>{photo?.name}</p>
									</div>
								</>
							}
						</div>
					))}
				</div>
			}

			{homePage?.phraseDesk && homePage?.phraseMobile &&
				<div className={styles.text}>

					{isDesktop &&
						<>
							<a href={homePage?.phraseLink} target="_blank" rel="noreferrer"> 
								<img src={homePage?.phraseDesk?._url} alt="" />
							</a>
						</>
					}
					{!isDesktop &&
						<>
							<a href={homePage?.phraseLink} target="_blank" rel="noreferrer">
								<img src={homePage?.phraseMobile?._url} alt="" />
							</a>
						</>
					}
					
					<div className={styles.spotify} dangerouslySetInnerHTML={innerHTML(homePage?.spotify)}></div>
				</div>
			}

			{homePage?.collectionProducts && homePage?.collectionProducts.map((item:any, index:number) => 
			(
				<NewestProductsSlider allLink={`/colecao/${item.slug}`} key={index} title={item.name} products={item.products} colorTable={colorTable} />
			))}

			{saleProducts && saleProducts?.length > 0 && <NewestProductsSlider allLink={`/sale/`} title="Sale" products={saleProducts} colorTable={colorTable} />}

			{homePage?.photosInstagram &&
				<PhotosIntagram title={homePage?.instaTitle} follow={homePage?.followInstagram} instagramFeeds={instagramFeeds} />
			}
        </div>
    );
}

const getStaticProps: GetStaticProps = () => withHeader(async () => 
{
	// HOME
	const [homePage, colorTable ] = await calls(
		getProduct(HOME_TWO_PAGE_SETTING),
		getDocument(COLOR_TABLE_SETTING),
		// getDocument(POPUP_PAGE_SETTING)
	);	

	const promises 		   = [];
	  let categoryProducts = [];


	if(!homePage.status)
	{
		return {
			notFound   : !homePage.serverError,
			revalidate : true,
		};
	}
	
	// PRODUTOS DA CATEGORIA
	if(homePage.data && homePage.data.categoryProducts)
	{
		for(const item of homePage.data.categoryProducts)
		{
			promises.push(collectionProductVariant(PARTIAL_PRODUCT_VARIANT_SETTING.merge(
			{
				perPage: 12,
				orderBy : 'categoryOrder',
				where: [
					{
						field	 : "published",
						operator : "==",
						value    : true,
					},	
					{
						field    : "indexes.categoriesxcolorxsize",
						operator : "combine",
						value    : [{
							referencePath : item.referencePath
						}],
					},
				],
			})));	
		}

		const values : any = await Promise.all(promises);

		for(const key in homePage.data.categoryProducts)
		{
			homePage.data.categoryProducts[key].products = values[key].collection || [];
		}

		categoryProducts = homePage.data.categoryProducts;
	}

	let saleProducts : any = {};

	// SALE
	if(homePage.data && homePage.data.sale)
	{
		saleProducts = await collectionProductVariant(PARTIAL_PRODUCT_VARIANT_SETTING.merge(
		{
			perPage : 12,
			orderBy : 'saleOrder',
			asc     : false,
			where: [
				{
					field: "published",
					operator: "==",
					value: true,
				},
				{
					field    : 'indexes.hasSale',
					operator : '==',
					value    : true
				}
			],
		}))
	}

	let newsProducts : any = {};

	// NOVIDADES
	if(homePage.data && homePage.data.news)
	{
		newsProducts = await collectionProductVariant(PARTIAL_PRODUCT_VARIANT_SETTING.merge(
		{
			perPage : 12,
			orderBy : 'order',
			where: [
				{
					field: "published",
					operator: "==",
					value: true,
				},				
			],
		}))
	}

	const instagramFeedsResult = await collectionDocument(INSTAGRAM_FEEDS_SETTING)

	return {
		revalidate : THEME_SETTING.revalidate,
		props: {
			homePage		 : homePage?.data   || [],
			categoryProducts : categoryProducts || [],
			colorTable 		 : colorTable?.data || {},
			saleProducts 	 : saleProducts?.collection || [],
			newsProducts 	 : newsProducts?.collection || [],
			instagramFeeds   : instagramFeedsResult.collection || [],
		},
	};
});

export { getStaticProps as GetStaticProps, HomePageTwo }